<template lang="html">
  <div class="form-controller col-12" :class="border">
    <div class="col-12">
      <div class="row  justify-content-start">
        <div class="rtl d-inline">
          <label :for="id" class="form-label" v-if="label">{{ label }}</label>
        </div>
        <div class="w-100 d-inline" v-if="label">
          <validation :rules="rules" :name="label" v-if="rules" v-slot="{ errors }">
          <input :type="type"
          :id="id"
          :value="value"
          v-bind="$attrs"
          v-on="listeners"
          class="form-input-text ">
          <span class="has-error">{{ errors[0] }}</span>
        </validation>
        <input :type="type"
        :id="id"
        :value="value"
        v-bind="$attrs"
        v-on="listeners"
        class="form-input-text" v-else>
        </div>
        <div class="col-12" v-else>
          <input :type="type"
          :id="id"
          :value="value"
          v-bind="$attrs"
          v-on="listeners"
          class="form-input-text">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { localize } from 'vee-validate';

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

export default {
  name:'TextInput',
  props:{
    id:String,
    type:String,
    label:String,
    border:String,
    value: {
        type: [String, Number],
        description: "Input value"
      },
    rules: String,
  },
  data(){
    return {
      focused:false,
    };
  },
  components: {
    'validation' : ValidationProvider,
  },
  computed: {
      hasIcon() {
        const { addonRight, addonLeft } = this.$slots;
        return addonRight !== undefined || addonLeft !== undefined || this.addonRightIcon !== undefined || this.addonLeftIcon !== undefined;
      },
      listeners() {
        return {
          ...this.$listeners,
          input: this.onInput,
          blur: this.onBlur,
          focus: this.onFocus
        }
      }
    },
    methods: {
      onInput(evt) {
        this.$emit('input', evt.target.value)
      },
      onFocus() {
        this.focused = true;
      },
      onBlur() {
        this.focused = false;
      }
    },
    mounted() {
      //do something after mounting vue instance
      localize(this.$route.params.language);
    },
    created() {
      //do something after creating vue instance
      localize(this.$route.params.language);
    }
}
</script>

<style lang="css" scoped>
.form-controller{
  border-left: none;
  border-right: none;
  border-radius: 0;
  border-top: none;
  padding-top: 7px;
  padding-bottom: 7px;
}
.form-label{
  font-size: 15px;
  color: #fff;
  font-weight: bold;
}
.form-input-text{
  margin: 0px 5px 0px 5px;
  padding: 5px;
  width: 100%;
  background: linear-gradient(90deg,rgba(255,255,255,0) 0% ,rgba(255,255,255,0.2)100%);
  border: none;
  color: #fff;
}
.rtl{
  direction: rtl;
  text-align: right;
}
.has-error{
  color: red;
}
</style>
