<template>
  <div class="container" :class="language">
    <ValidationObserver ref="form">
    <div class="row justify-content-center">
      <div class="col-12 comment-form">
        <select-input :label="$t('representationForm.space')" :options="placeMode" type="text" id="placeMode" @input="setSelected($event,'placeMode')"></select-input>
      </div>
      <div class="col-12 comment-form">
        <select-input :label="$t('representationForm.malekiyat')" id="malekiyat" :options="malekiyat" @input="setSelected($event,'malekiyat')"></select-input>
      </div>
      <div class="col-12 comment-form">
        <text-input :label="$t('representationForm.modat')" type="text" id="ejare" v-model="form.ejare"></text-input>
      </div>
      <div class="col-12 comment-form">
        <text-input :label="$t('representationForm.karbari')" rules="required|max:100" type="text" id="workMode" v-model="form.workMode"></text-input>
      </div>
      <div class="col-12 comment-form">
        <text-input :label="$t('representationForm.sellPerson')" rules="required|numeric|max:3" type="text" id="sellPerson" v-model="form.sellPerson"></text-input>
      </div>
      <div class="col-12 comment-form">
        <text-input :label="$t('representationForm.storagePerson')" type="text" rules="required|numeric|max:3" id="storePerson" v-model="form.storePerson" ></text-input>
      </div>
    </div>
  </ValidationObserver>
  </div>
</template>

<script>
import TextInput from '../inputs/TextInput';
import SelectInput from '../inputs/SelectInput';
 import * as VeeValidate from 'vee-validate';
export default {
  name:'MetaPlace',
  components: {
    TextInput,
    SelectInput,
    'ValidationObserver':VeeValidate.ValidationObserver,
  },
  data(){
    return {
      language:this.$route.params.language,
      placeMode:[
        {label:this.$t("representationForm.edari"), code:'0'},
        {label:this.$t("representationForm.anbar"),code:'1'},
        {label:this.$t("representationForm.foroshgahi"),code:'2'},
      ],
      malekiyat:[
        {label:this.$t("representationForm.malek") , code:0},
        {label:this.$t("representationForm.estijari") , code:1}
      ],
      form:{
        placeMode:null,
        malekiyat:null,
        ejare:new String(),
        workMode:new String(),
        sellPerson:new String(),
        storePerson:new String(),
      }
    };
  },
  methods: {
    setSelected(evt,prop) {
      this.form[prop] = evt.code;
    },
    async validate(){
      const isValid = await this.$refs.form.validate();
      //console.log(isValid);
      return isValid;
    }
  }
}
</script>

<style lang="css" scoped>
</style>
